module.exports = [
  {
    id: 1,
    title: "Presentation",
    menuList: [
      { name: "Le Senegal", routerPath: "/memorial/presentation/lesenegal" },
      { name: "Bateau Le Joola", routerPath: "/memorial/presentation/lejoola" },
    ],
  },
  {
    id: 2,
    title: "Le Memorial",
    menuList: [
      { name: "Histoire du Memorial", routerPath: "/memorial/lememorial/histoiredumemorial" },
      { name: "Parcours du Projet", routerPath: "/memorial/lememorial/parcoursduprojet" },
      { name: "Discours Muséographique", routerPath: "/memorial/lememorial/discoursmuseographique" },
      { name: "Visite Guidée", routerPath: "/memorial/lememorial/visiteguidee" },
      // { name: "Sitemap", routerPath: "#" },
    ],
  },
  {
    id: 3,
    title: "Activites & Programmes",
    menuList: [
      { name: "Expositions", routerPath: "/memorial/activitesetprogrammes/expositions" },
      { name: "Espaces Culturels", routerPath: "/memorial/activitesetprogrammes/espacesculturels" },
      { name: "Programmes pédagogiques", routerPath: "/memorial/activitesetprogrammes/programmespedagogiques" },
    ],
  },
];
